<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return{
            view: 1,
            rutas: [
                { titulo: 'Traslados de stock', ruta: 'admin.cedis.supercedis.traslados.stock' },
                { titulo: 'Estadísticas', ruta: 'admin.cedis.supercedis.estadisticas' },
            ]
        }
    },
    created(){
    }
}
</script>

<style lang="scss" scoped>

</style>
